<template>
  <div class="user">
    <My-header v-if="this.title" :topicId="this.id"></My-header>
    <My-message v-if="this.title == '留言'" :topicId="this.id"></My-message>
    <My-interested v-if="this.title == '感兴趣'" :topicId="this.id"></My-interested>
    <My-contact v-if="this.title == '想联系'" :topicId="this.id"></My-contact>
  </div>
</template>


<script>
import MyHeader from './header.vue'
import MyMessage from './message.vue'
import MyInterested from './interested.vue'
import MyContact from './contact.vue'
export default {
  components: { MyHeader, MyMessage, MyInterested, MyContact },
  data () {
    return {
      title:'',
      id:''
    }
  },
  mounted(){
    this.title = this.$route.query.title
    this.id = this.$route.query.id
    this.$route.meta.title = this.$route.query.title
  },
  methods: {},
}
</script> 

<style scoped lang="less">
.user{
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
}
</style>
