<template>
  <div>
    <div class="line_two">
      <el-form inline :model="queryInfo" label-width="120px" ref="form" class="elFrom">
        <el-button
          :class="tabIndex1 === '-1' ? 'active' : ''"
          style="margin-left: 10px"
          @click="searchClick('-1')"
          >全部</el-button
        >
        <el-button :class="tabIndex1 === '1' ? 'active' : ''" @click="searchClick('1')"
          >已回复</el-button
        >
        <el-button :class="tabIndex1 === '0' ? 'active' : ''" @click="searchClick('0')"
          >未回复</el-button
        >
        <el-form-item label="最新消息时间:" style="margin-left: 50px">
          <el-radio-group v-model="queryInfo.commentDate" @change='search()'>
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1">近三天</el-radio>
            <el-radio :label="2">近一周</el-radio>
            <el-radio :label="3">近一个月</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="leave" v-if="messageList.length != 0">
        <el-card style="margin-top: -5px">
          <div class="leave_list">
            <div class="leave_list_item" v-for="(item, index) in messageList" :key="index">
              <div class="leave_list_item_left">
                <img :src="item.headPortrait" alt="" />
                <span v-if="item.replyStatus == 1">已回复</span>
                <span v-if="item.replyStatus == 0">未回复</span>
              </div>
              <div class="leave_list_item_right" v-show="item.isLong">
                <div class="leave_list_item_right_list">
                  <div class="block_up">
                    <div class="block" v-for="(tt, ii) in item.shortList" :key="ii">
                      <div class="leave_list_item_right_list_p">
                        <p>{{ tt.comment }}</p>
                        <div class="cosle" @click="deleteClose(tt)">删除</div>
                      </div>
                      <span>{{ tt.commentTime }}</span>
                    </div>
                  </div>
                </div>
                <el-button type="text" @click="getAll()">全部消息</el-button>
              </div>
              <div class="leave_list_item_right" v-show="!isLong">
                <div class="leave_list_item_right_list">
                  <div class="block_up">
                    <div class="block" v-for="(tt, ii) in item.commentList" :key="ii">
                      <div class="leave_list_item_right_list_p">
                        <p>{{ tt.comment }}</p>
                        <div class="cosle" @click="deleteClose(tt)">删除</div>
                      </div>
                      <span>{{ tt.commentTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="notData" v-else>
      暂无数据
      </div>
    </div>
    <div class="new_page" v-if="messageList.length != 0">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import { pagination } from '@/mixins/pagination'
import { deleteMessage, getAllMessage } from '@/api/businessTopics.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
  replyStatus:'-1',
  commentDate:0
})
export default {
  components: {},
  mixins: [pagination],
  props:{
    topicId:{
      type:String
    }
  },
  data() {
    
    return {
      queryInfo: { ...defaultQueryInfo },
      tabIndex1: '-1',
      from: {
        value: 0,
      },

      count: 0,
      tabIndex: 0,
      tabList: ["全部", "已回复", "未回复"],
      messageList: [], //聊天数据
    }
  },
  created() {
    this.queryInfo.topicId = this.topicId
    this.search();
  },
  methods: {
    getAll(){
      this.isLong = false
    },
    //按钮选中css
    searchClick(index) {
      this.tabIndex1 = index
      this.queryInfo.replyStatus = index
      this.search()
    },
    //删除
    deleteClose(row) {
      this.$confirm(`确认删除这条评论吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await deleteMessage({ id: row.id });
          if (res.resultCode == 200) {
            this.$messageSuccess(`评论删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
    //获取交流分页
    async search() {
      const { data: res } = await getAllMessage(this.queryInfo);
      if (res.resultCode === 200) {
        this.messageList = res.data.list;
        this.messageList.map(item =>{
          item.shortList = item.commentList.slice(0,2)
        })
        // console.log(this.messageList);
        this.total = res.data.total;

      }
    },
  },
}
</script> 

<style scoped lang="less">
.el-button--primary {
  background: #7c71e1;
}
.el-button:hover,
.el-button:active {
  background: #7c71e1;
  color: #ffffff;
}
.active {
  background: #7c71e1;
  color: #ffffff;
  border: none;
}
.line_two {
  background: #ffffff;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 12px;
  .elFrom {
    margin: 20px 0px 0px 20px;
  }
}
.notData{
  width: 100%;
  text-align: center;
  background: #ffffff;
  margin-bottom: 50px;
}
.new_page {
  width: 100%;
  text-align: center;
  margin: 30px auto;
}
.leave {
  width: 100%;

  /deep/ .el-form-item {
    margin-bottom: 0;
  }

  /deep/ .el-form-item__label {
    color: #252631;
    font-size: 14px;
    font-weight: bold;
  }

  .leave_table {
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    padding: 5px 10px;
    background-color: #f6f6f6;
    border-radius: 2px;

    .leave_table_li {
      cursor: pointer;
      margin-right: 40px;
      box-sizing: border-box;
      float: left;
      padding: 8px 15px;
      overflow: hidden;
      border-radius: 2px;
      color: #151515;
      font-weight: 400;
      font-size: 14px;
    }

    .leave_table_li:nth-last-child(1) {
      margin-right: 0;
    }

    .active {
      background-color: #8483d0;
      color: #ffffff;
    }
  }

  .leave_list {
    width: 100%;
    overflow: hidden;
    margin-top: 30px;

    .leave_list_item {
      width: 100%;
      overflow: hidden;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .leave_list_item_left {
        width: 52px;
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 52px;
          height: 52px;
          border-radius: 2px;
          display: block;
        }

        span {
          box-sizing: border-box;
          border-radius: 2px;
          background-color: rgba(132, 131, 208, 0.1);
          display: inline-block;
          width: 52px;
          height: 24px;
          line-height: 24px;
          color: #8483d0;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          margin-top: 4px;
        }
      }

      .leave_list_item_right {
        flex: 1;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        border-radius: 2px;
        background-color: #f6f6f6;

        .leave_list_item_right_list {
          width: 100%;
          overflow: hidden;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .block_up {
            flex: 1;
            overflow: hidden;
          }
          .block {
            width: 100%;
            overflow: hidden;
            margin-bottom: 20px;

            .leave_list_item_right_list_p {
              width: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              p {
                flex: 1;
                font-size: 16px;
                color: #151515;
                font-weight: 400;
              }
              .cosle {
                width: 56px;
                cursor: pointer;
                margin-left: 24px;
                color: #999999;
                font-weight: 400;
                font-size: 14px;
                display: block;
              }
            }
            span {
              color: #999999;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .huifu {
            color: #8483d0;
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
          }

          .sanjiao {
            width: 0;
            height: 0;
            position: absolute;
            left: -8px;
            top: 10px;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            border-right: 10px solid #f6f6f6;
          }
        }
      }
    }
  }
}
</style>
