<template>
   <div class="line_one">
      <div class="goods_img">
        <div class="item_img">
          <img :src="this.dataUserList.imgList[0]">
        </div>
      </div>
      <div class="goods_text">
        <!-- <div v-html="this.dataUserList.topicDescribe" ></div> -->
        <textFlow :info="this.dataUserList.topicDescribe" :hiddenBtn='true' :lineClamp='4' class="text_area"></textFlow>
      </div>
    </div>
</template>


<script>
import textFlow from '@/components/textflow.vue'
import { getDetail } from '@/api/businessTopics.js'
export default {
  components: { textFlow },
  props:{
    topicId:{
      type:String
    }
  },
  data () {
    return {
      dataUserList:{},
      id:''
    }
  },
  mounted() {
    this.id = this.topicId
    // console.log(this.id,"header");
    this.getDetail()
  },
  methods: {
    async getDetail(){
      const { data: res } = await getDetail({id:this.id})
      if (res.resultCode === 200) {
        this.dataUserList = res.data
        // console.log(res,"res1231");
      }
    }
  },
}
</script> 

<style scoped lang="less">
.line_one{
  width: 100%;
  height: 120px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  .goods_img{
    width: 200px;
    .item_img{
      width: 140px;
      height: 90px;
      border-radius: 8px;
      margin: 16px 16px 16px 20px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .goods_text{
    width: 80%;
    margin-top: 16px;
    .text_area{
      width: 100%;
      color: #333333;
      font-size: 12px;
      line-height: 18px;
      img{
        width: 100px;
        height: 100px;
      }
    }
  }
} 

</style>
