<template>
 <div>
    <div class="line_two">
      <el-table :data="getAttentionList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
          <el-table-column label="用户ID" prop="id" align="center" width="80" />
          <el-table-column label="头像" width="100" align="center">
              <template v-slot="{ row }">
              <div class="headImg">
                  <img :src="row.headPortrait"/>
              </div>
              </template>
          </el-table-column>
          <el-table-column label="真实姓名" prop="userName" align="center" width="80" />
          <el-table-column label="手机号" prop="mobile" align="center" width="120" />
          <el-table-column label="机构" prop="institution" align="center" width="100" />
          <el-table-column label="职位" prop="position" align="center" width="80" />
          <el-table-column label="标签" align="center" width="300">
            <template v-slot="{ row }">
            <div class="tip_two">
              <div v-for="(item,index) in row.label" :key="index">{{item}}</div>
            </div>
          </template>
          </el-table-column>
          <el-table-column label="用户等级" prop="whetherMember" align="center" width="100" />
          <el-table-column label="用户简介" align="center" prop="personalIntro" :show-overflow-tooltip="true"/>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import { pagination } from '@/mixins/pagination'
import { getInterested } from '@/api/businessTopics.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
})
export default {
  props:{
    topicId:{
      type:String
    }
  },
  components: {},
  mixins: [pagination],
  data () {
    return {
        getAttentionList:[],
        loading:false,
        queryInfo: { ...defaultQueryInfo },
    }
  },
  mounted(){    
    this.queryInfo.topicId = this.topicId
    this.search()
  },
  methods: {
    async search() {
      this.loading = true
      const { data: res } = await getInterested(this.queryInfo)
      if (res.resultCode === 200) {
        this.getAttentionList = res.data.list
        this.getAttentionList.map(item=>{
          item.label = item.label.split(',')
        })
        this.total = res.data.total
        this.loading = false
      }
    },
  },
}
</script> 

<style scoped lang="less">
.line_two{
  background: #ffffff;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 12px;
  .data_list{
    margin: 20px 0px 0px 20px;
  }
}
.new_page {
  width: 100%;
  text-align: center;
  margin: 30px auto;
}
.tip_two{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8px;
  div{
    padding: 2px 5px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 17px;
    color: #7067F2;
    background: #7067F21A;
    margin-right: 8px;
  }
}
.headImg{
  margin: 0px auto;
  width: 45px;
  height: 45px; 
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
