<template>
  <div>
    <div class="line_two">
      <div class="state">
        <span>状态:</span>
        <el-button :class="tabIndex==='-1'?'active':''" style="margin-left:10px" @click="searchClick('-1')" >全部</el-button>
        <el-button :class="tabIndex==='1'?'active':''" @click="searchClick('1')">已联系</el-button>
        <el-button :class="tabIndex==='0'?'active':''" @click="searchClick('0')">未联系</el-button>
      </div>
      <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column label="用户ID" prop="id" align="center" width="80"/>
        <el-table-column label="头像" width="100" align="center">
          <template v-slot="{ row }">
            <div class="headImg">
              <img
              :src="row.headPortrait"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center" width="80"/>
        <el-table-column label="手机号" prop="mobile" align="center" width="80"/>
        <el-table-column label="机构" prop="institution" align="center" width="100"/>
        <el-table-column label="职位" prop="position" align="center"  width="80" />
        <el-table-column label="标签" prop="label" align="center"  width="200">
         <template v-slot="{ row }">
            <div class="tip_two">
              <div v-for="(item,index) in row.label" :key="index">{{item}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户等级" prop="whetherMember" align="center"  width="100">
          <template v-slot="{ row }">
            <div>
              {{row.whetherMember == 0 ? '普通会员' : '超级会员'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户简介" prop="personalIntro" align="center"/>
        <el-table-column label="操作" align="center" width="250">
         <template v-slot="{ row }">
            <div v-if="row.contactStatus == 0" class="notContact">未联系</div>
            <div v-if="row.contactStatus == 1" class="contact">已联系</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import { pagination } from '@/mixins/pagination'
import { getContact } from '@/api/businessTopics.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
  contactStatus:'-1'
})
export default {
  props:{
    topicId: {
      type:String
    }
  },
  components: {},
  mixins: [pagination],
  data () {
    return {
      queryInfo: { ...defaultQueryInfo },
    	tabIndex:'-1',
      loading:false,
      dataUserList:[]
    }
  },
  mounted(){    
    this.queryInfo.topicId = this.topicId
    this.search()
  },
  methods: {
    //按钮选中css
    searchClick(index) {
      this.tabIndex = index
      this.queryInfo.contactStatus = index
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getContact(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.dataUserList.map(item=>{
          if(item.label) item.label = item.label.split(',')
        })
        this.total = res.data.total
        this.loading = false
      }
    },
  },
}
</script> 

<style scoped lang="less">
.headImg{
  margin: 0px auto;
  width: 45px;
  height: 45px; 
  img{
    width: 100%;
    height: 100%;
  }
}
.el-button--primary{
  background: #7C71E1;
}
.el-button:hover,
.el-button:active {
  background: #7C71E1;
  color: #ffffff;
}
.active{
  background: #7C71E1;
  color: #ffffff;
  border: none;
}
.tip_two{
  display: flex;
  justify-content: center;
  div{
    margin: 0px 5px;
    padding: 2px 8px;
    border-radius: 5px;
    background: rgba(112, 103, 242, 0.1);
    color: #7067F2;
  }
}
.line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .state{
      margin: 25px 0px 20px 20px;
    }
    .data_list{
      margin: 0 0 0 20px;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }

</style>
